import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import { appendSNToDataList } from "../../utils/TableUtils";

const columns = [
  { field: "sn", headerName: "SN", width: 50 },
  { field: 'name', headerName: 'Name', width: 130 },
  {
    field: "status", headerName: "Status", width: 130,
    renderCell: (params) => (
      params.row.status === "Y" ?
        <MDBox ml={-1}>
          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
        </MDBox>:
        <MDBox ml={-1}>
          <MDBadge badgeContent="Inactive" color="warning" variant="gradient" size="sm" />
        </MDBox>
    )
  }
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];

export default function SpaceTable(props) {

  const {isLoading,spaceList}=props
  return (
    <div style={{ height: 550, width: '100%' }}>
      <DataGrid
        rows={appendSNToDataList(spaceList)}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        loading={isLoading}
        // checkboxSelection
      />
    </div>
  );
}
