// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import UserTable from "./UserTable";
import { useEffect, useState } from "react";
import { searchUser } from "./Services";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { AddBox } from "@mui/icons-material";
import UserAdd from "./UserAdd";

function User() {

  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(async () => {

    await fetchUserSearch();

  }, []);

  const fetchUserSearch = async () => {

    let searchRequestDTO = {
      name: "",
    };

    try {
      const response = await searchUser(searchRequestDTO);
      const userList = response.data;
      setUserList(userList);
      setIsLoading(false);
    } catch (e) {
      alert(e.errorMessage);

    }

  };

  const showAddModal = async (flag) => {

    setShowAdd(flag);

  };


  return (

    <DashboardLayout>
      <DashboardNavbar />

      {showAdd ?
        <UserAdd
          showAddModal={showAddModal}
          showAdd={showAdd}
          fetchUserSearch={fetchUserSearch}
        /> : ""}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => showAddModal(true)}
                    color="inherit"
                  >
                    <AddBox />
                  </IconButton>
                </MDTypography>

              </MDBox>

              <MDBox py={3}>
                <UserTable
                  userList={userList}
                  isLoading={isLoading}
                />
              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default User;
