import Axios from "../../axios-interceptor";
import { tableApiConstants } from "../../constants/AdminApiConstants";

export async function saveTables(params) {
  return Axios.post(tableApiConstants.SAVE_TABLE, params);
}

export async function searchTables(data) {
  return Axios.put(tableApiConstants.SEARCH_TABLE, data, {params: {page: 1, size: 10}});
}
