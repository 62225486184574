import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import Button from "@mui/material/Button";
import QRCode from "react-qr-code";

const BillPage = (props) => {

  const {
    showTableQr,
    setShowQrModal,
    table,
  } = props;

  console.log("print:::", showTableQr);

  const printBill = (props) => {

    alert("Bill is printed successfully");

  };

  const { id, name } = table;

  return (

    <>
      <div>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={showTableQr}
          onClose={() => setShowQrModal(false)}>
          <DialogTitle>Table QR Code</DialogTitle>


          <DialogContent>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <div className="tableQr">
                    {/*// Can be anything instead of `maxWidth` that limits the width.*/}
                    <div style={{ height: "auto", margin: "50 auto", maxWidth: 500, width: "100%" }}>
                      <QRCode
                        size={500}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={id + " " + name}
                        viewBox={`0 0 500 500`}
                      />
                    </div>
                  </div>
                </Grid>

              </Grid>
            </MDBox>

          </DialogContent>
          <DialogActions>
            <Button variant="text" color={"warning"} onClick={() => setShowQrModal(false)}>Cancel</Button>
            <Button variant="text" onClick={() => printBill()}>Print</Button>
          </DialogActions>
        </Dialog>
      </div>

    </>

  );

};

export default BillPage;
