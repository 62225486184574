import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import Button from "@mui/material/Button";

const BillPage = (props) => {

  const {
    billPage,
    setBillPage,
    orderList,
  } = props;

  console.log("print:::", billPage);

  const printBill = (props) => {

    alert("Bill is printed successfully");

  };

  return (

    <>

      <div>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={billPage}
          onClose={() => setBillPage(false)}>
          <DialogTitle>Table Billing</DialogTitle>
          <DialogContent>
            {/*<DialogContentText>*/}
            {/*  Create new Order*/}
            {/*</DialogContentText>*/}
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>

                  <div className="ticket">
                    <img src="./logo.png" alt="Logo" />
                    <p className="centered">RECEIPT
                      <br></br>Address line 1
                      <br></br>Address line 2</p>
                    <table>
                      <thead>
                      <tr>
                        <th className="quantity">Sn.</th>
                        <th className="description">Particulars</th>
                        <th className="description">Qty</th>
                        <th className="description">Rate</th>
                        <th className="description">Amount</th>
                        <th className="price">$$</th>
                      </tr>
                      </thead>
                      <tbody>
                      {console.log("wwwww::::", orderList)}
                      {orderList.map((order, index) => {
                        const {
                          totalAmount,
                          itemName,
                          price,
                          quantity,
                        } = order;
                        return (

                          <tr>
                            <td className="quantity">{index + 1}</td>
                            <td className="description">{itemName}</td>
                            <td className="quantity">{quantity}</td>
                            <td className="rate">{price}</td>
                            <td className="price">Rs. {totalAmount}</td>
                          </tr>
                        );

                      })}


                      </tbody>
                    </table>
                    <p className="centered">Thanks for your purchase!
                      <br />parzibyte.me/blog</p>
                  </div>
                </Grid>

              </Grid>
            </MDBox>

          </DialogContent>
          <DialogActions>
            <Button variant="text" color={"warning"} onClick={() => setBillPage(false)}>Cancel</Button>
            <Button variant="text" onClick={() => printBill()}>Print</Button>
          </DialogActions>
        </Dialog>
      </div>

    </>

  );

};

export default BillPage;
