// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
// Dashboard components
import OrdersTable from "./OrdersTable";
import { useEffect, useState } from "react";
import { searchOrder } from "./Services";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { SearchRounded } from "@mui/icons-material";

function Orders() {

  const [ordersList, setOrdersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchFilter, setSearchFilter] = useState({
    name: "",
  });

  useEffect(async () => {

    await fetchOrdersSearch();

  }, []);

  const fetchOrdersSearch = async () => {

    let searchRequestDTO = {
      name: "",
    };

    try {
      const response = await searchOrder(searchRequestDTO);
      const ordersList = response.data;
      setOrdersList(ordersList);
      setIsLoading(false);
    } catch (e) {

    }

  };


  const fetchItemSearch = async () => {

    // try {
    //   const response = await searchItem(searchFilter);
    //   const itemList = response.data;
    //   setItemList(itemList);
    // } catch (e) {
    //   // alert(e.errorMessage);
    //   setItemList([]);
    //
    // }

  };

  const handleSearch = async (event) => {

    const { name, value } = event.target;

    setSearchFilter({
      name: value,
    });

  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>

            <Grid item xs={12} md={6} lg={12}>

              <MDBox px={1} py={1}>
                <Stack direction="row" spacing={2}>
                  From Date: <TextField
                  id="outlined-basic"
                  // label="From Date"
                  type={"date"}
                  name={"fromDate"}
                  onChange={handleSearch}
                  variant="outlined" />
                  To Date: <TextField
                  id="outlined-basic"
                  // label="To Date"
                  type={"date"}
                  name={"name"}
                  onChange={handleSearch}
                  variant="outlined" />
                  <TextField
                    id="outlined-basic"
                    label="Table"
                    variant="outlined" />
                  <Button
                    variant="contained"
                    startIcon={<SearchRounded />}
                    onClick={() => fetchItemSearch()}
                  >
                    Search
                  </Button>
                </Stack>

              </MDBox>

              <OrdersTable
                ordersList={ordersList}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Orders;
