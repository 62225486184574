import Axios from "../axios-interceptor";
import {
  categoryApiConstants,
  fileApiConstants,
  spaceApiConstants,
  userApiConstants,
} from "../constants/AdminApiConstants";

export async function fetchActiveCategoryDropdown() {
  return Axios.get(categoryApiConstants.CATEGORY_DROPDOWN);
}

export async function fetchActiveSpaceDropdown() {
  return Axios.get(spaceApiConstants.SPACE_DROPDOWN);
}

export async function uploadFile(params) {
  return Axios.post(fileApiConstants.UPLOAD_FILE, params)
}


export async function fetchLoggedInUserInfo() {
  return Axios.get(userApiConstants.USER_INFO)
}
