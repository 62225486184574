import { useState } from "react";

// react-router-dom components
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";

// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";


// Images
import bgImage from "assets/images/welcome-screen.png";
import { setTokenToLocalStorage } from "../../../utils/LocalStorageUtils";
import { loginUser } from "./Services";
import MDAlert from "../../../components/MDAlert";
import { fetchLoggedInUserInfo } from "../../CommonServices";

const alertContent = (name) => (
  <MDTypography variant="body2" color="white">
    Login Error
    {/*<MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">*/}
    {/*  an example link*/}
    {/*</MDTypography>*/}
    {/*. Give it a click if you like.*/}
  </MDTypography>
);

function Basic() {

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [error, setError] = useState(false);


  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const handleOnChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    console.log(name + ":::" + value);

    setLogin({
      ...login,
      [name]: value,
    });
  };

  const signIn = async (e) => {
    e.preventDefault();

    console.log("login is clicked.....");

    const { email, password } = login;

    console.log("email:::" + email);
    console.log("password:::" + password);

    let requestDTO = {
      email: email,
      password: password,
    };


    try {
      const response = await loginUser(requestDTO);
      setTokenToLocalStorage("restaurant-user", response.data);


      const userResponse = await fetchLoggedInUserInfo();
      await setTokenToLocalStorage("userInfo", userResponse.data);

      window.location.href = "/#/dashboard";

    } catch (e) {
      // alert("error" + e.errorMessage);
      setError(true);
    }


  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            Welcome to Restaurant Application
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                name={"email"}
                label="Email"
                required={"required"}
                onChange={handleOnChange}
                fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name={"password"}
                label="Password"
                required={"required"}
                fullWidth
                onChange={handleOnChange} />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="warning" fullWidth
                onClick={signIn}
                disabled={!login.email || !login.password}
              >
                sign in
              </MDButton>
              {error ?
                <MDAlert color="error" dismissible>
                  {alertContent("error")}
                </MDAlert> : ""}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
