// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import ItemTable from "./ItemTable";
import { useEffect, useState } from "react";
import { searchItem } from "../item/Services";
import ItemAdd from "../item/ItemAdd";
import MDTypography from "../../components/MDTypography";
import IconButton from "@mui/material/IconButton";
import { AddBox, SearchRounded } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";

function Item() {

  const [itemList, setItemList] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [searchFilter, setSearchFilter] = useState({
    name: "",
  });


  useEffect(async () => {

    await fetchItemSearch();

  }, []);

  const handleSearch = async (event) => {

    const { name, value } = event.target;

    setSearchFilter({
      name: value,
    });

  };

  const fetchItemSearch = async () => {

    try {
      const response = await searchItem(searchFilter);
      const itemList = response.data;
      setItemList(itemList);
    } catch (e) {
      // alert(e.errorMessage);
      setItemList([]);

    }

  };

  const showAddModal = async (flag) => {

    setShowAdd(flag);

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {showAdd ?
        <ItemAdd
          showAddModal={showAddModal}
          showAdd={showAdd}
          fetchItemSearch={fetchItemSearch}
        /> : ""}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Item
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => showAddModal(true)}
                    color="inherit"
                  >
                    <AddBox />
                  </IconButton>
                </MDTypography>

              </MDBox>

              <MDBox px={1} py={1}>
                <Stack direction="row" spacing={2}>
                  <TextField id="outlined-basic" label="Category" name={""} onChange={handleSearch}
                             variant="outlined" />
                  <TextField id="outlined-basic" label="Name" name={"name"} onChange={handleSearch}
                             variant="outlined" />
                  <TextField id="outlined-basic" label="Status" variant="outlined" />
                  <Button
                    variant="contained"
                    startIcon={<SearchRounded />}
                    onClick={() => fetchItemSearch()}
                  >
                    Search
                  </Button>
                </Stack>

              </MDBox>

              <MDBox py={3}>
                <ItemTable
                  itemList={itemList} />
              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Item;
