import * as React from "react";
// react-routers components
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import List from "@mui/material/List";
import { ListItem, ListItemAvatar } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import homeDecor1 from "../../assets/images/items/1.jpg";

const Category = (props) => {

  const {
    categoryList,
    filterItemsByCategory,
  } = props;
  console.log("data::::", categoryList);

  return (
    <Card sx={{
      height: "100%",
      // boxShadow: !shadow && "none"
    }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {name}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>


          {categoryList && categoryList.map((category, index) => {
              const { id, name, price } = category;
              return (
                <List sx={{
                  width: "100%",
                  maxWidth: 360,
                  overflow: "auto",
                  position: "relative",
                  maxHeight: 100,
                  bgcolor: "background.paper",
                }}
                >
                  <ListItem
                    alignItems="flex-start"
                    onClick={() => filterItemsByCategory(id)}
                  >
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={homeDecor1} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {name}
                          </Typography>
                          {/*{" — I'll be in your neighborhood doing errands this…"}*/}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              );
            },
          )}


        </MDBox>
      </MDBox>
    </Card>
  );
};

// Setting default props for the ProfilesList
Category.defaultProps = {
  // shadow: true,
};

// Typechecking props for the ProfilesList
Category.propTypes = {
  // title: PropTypes.string.isRequired,
  categoryList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // shadow: PropTypes.bool,
};

export default Category;
