import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import WineBarIcon from "@mui/icons-material/WineBar";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import CategoryIcon from "@mui/icons-material/Category";

import Quote from "../../data/Quotes.json";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const greetings = () => {

  const today = new Date();
  console.log("ttt", today);
  const hours = today.getHours();
  console.log("hourrrrrssssss", hours);

  return hours < 12 ? "Good Morning" :
    hours < 18 ? "Good Afternoon" :
      "Good Evening";
};

const getQuotes = () => {

  const quote = JSON.parse(JSON.stringify(Quote));
  console.log("size", quote.length);
  const size = Math.floor(Math.random() * quote.length);

  const result = quote[size].quote;
  return result;


};

const card = (
  <React.Fragment>
    <CardContent sx={{ backgroundColor: "#FFC70B" }}>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Food of the Day
      </Typography>
      <Typography variant="h5" component="div">
        Chicken Momo
      </Typography>
      {/*<Typography sx={{ mb: 1.5 }} color="text.secondary">*/}
      {/*  Quote of the Day*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">*/}
      {/*  {getQuotes()}*/}
      {/*</Typography>*/}

    </CardContent>
    {/*<CardActions>*/}
    {/*  <Button size="small">Learn More</Button>*/}
    {/*</CardActions>*/}
  </React.Fragment>
);

const foodnumbers = (tiles) => {

  const {
    totalTable,
    totalSpace,
    totalKitchenItem,
    totalBeverageItem,
    totalCategory,
  } = tiles;

  return (
    <React.Fragment>
      <CardContent sx={{ backgroundColor: "#FFC70B" }}>

        <CategoryIcon fontSize={"large"} color={"error"} /> {totalCategory}
        <FoodBankIcon fontSize={"large"} color={"error"} /> {totalKitchenItem}
        <WineBarIcon fontSize={"large"} color={"error"} /> {totalBeverageItem}
        <TableRestaurantIcon fontSize={"large"} color={"error"} /> {totalTable}
      </CardContent>
    </React.Fragment>
  );
};

export default function BestFood({ tiles }) {

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{foodnumbers(tiles)}</Card>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}