import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import { appendSNToDataList } from "../../utils/TableUtils";

const columns = [
  { field: "sn", headerName: "SN", width: 50 },
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "type", headerName: "Type", width: 150,
    renderCell: (params) => (
      <MDBox ml={-1}>
        <MDBadge badgeContent="KOT" color={"secondary"} size="sm" />
      </MDBox>
    )
  },
  {
    field: "status", headerName: "Status", width: 130,
    renderCell: (params) => (
      params.row.status === "Y" ?
        <MDBox ml={-1}>
          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
        </MDBox> :
        <MDBox ml={-1}>
          <MDBadge badgeContent="Inactive" color="warning" variant="gradient" size="sm" />
        </MDBox>
    ),
  },
];

export default function CategoryTable(props) {

  const { categoryList } = props;
  return (
    <div style={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={appendSNToDataList(categoryList)}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
      />
    </div>
  );
}
