import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { FormControl, Input, InputLabel, OutlinedInput, Select, useTheme } from "@mui/material";
import { saveTables } from "./Services";
import MenuItem from "@mui/material/MenuItem";
import { fetchActiveSpaceDropdown } from "../CommonServices";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function TableAdd(props) {

  const {
    showAdd,
    showAddModal,
    fetchTablesSearch,
  } = props;
  console.log("ddd" + showAdd);

  const theme = useTheme();
  const [table, setTable] = useState({
    name: "",
    spaceId: null,
    code: "",
    capacity: "",
    description: "",
  });

  const [spaceList, setSpaceList] = React.useState([]);

  useEffect(async () => {

    if (showAdd) {
      await fetchActiveSpaceForDropdown();
    }

  }, [showAdd]);

  const fetchActiveSpaceForDropdown = async () => {

    try {
      const response = await fetchActiveSpaceDropdown();
      setSpaceList(response.data);
    } catch (e) {
      alert(e.errorMessage);

    }

  };

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    console.log(name + ":::" + value);
    setTable({
      ...table,
      [name]: value,
    });

  };

  const submitTable = async () => {

    try {
      await saveTables(table);
      showAddModal(false);
      fetchTablesSearch();
    } catch (e) {
      alert(e.errorMessage);

    }

  };

  const { name, spaceId, capacity,code, description } = table;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showAdd}
        onClose={() => showAddModal(false)}>
        <DialogTitle>Add Table</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create new Table
          </DialogContentText>

          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl variant="standard">
              <InputLabel htmlFor="component-simple">Name</InputLabel>
              <Input
                id="component-simple"
                name={"name"}
                value={name}
                onChange={handleOnChange} />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel htmlFor="component-simple">Capacity</InputLabel>
              <Input
                id="component-simple"
                name={"capacity"}
                value={capacity}
                onChange={handleOnChange} />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel htmlFor="component-simple">Code</InputLabel>
              <Input
                id="component-simple"
                name={"code"}
                value={code}
                onChange={handleOnChange} />
            </FormControl>

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Name</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={spaceId}
                name={"spaceId"}
                onChange={handleOnChange}
                placeholder={"Please Select"}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {spaceList && spaceList.map((space) => (
                  <MenuItem
                    key={space.value}
                    value={space.value}
                  >
                    {space.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth variant="standard">
              <TextField
                id="outlined-textarea"
                label="Description"
                placeholder="Description"
                name={"description"}
                value={description}
                value={description}
                onChange={handleOnChange}
                multiline
                autoSize
              />
            </FormControl>

          </Box>

        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => showAddModal(false)}>Cancel</Button>
          <Button variant="text" onClick={() => submitTable()}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TableAdd;
