import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import Autocomplete from "@mui/material/Autocomplete";
import { Stack } from "@mui/material";
import Spinner from "../../components/Loader/Spinner";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  { field: "code", headerName: "Code", width: 130 },
  { field: "capacity", headerName: "Capacity", width: 130 },
  { field: "spaceName", headerName: "Space", width: 130 },
  {
    field: "status", headerName: "Status", width: 130,
    renderCell: (params) => (
      params.row.status === "Y" ?
        <MDBox ml={-1}>
          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
        </MDBox> :
        <MDBox ml={-1}>
          <MDBadge badgeContent="Inactive" color="warning" variant="gradient" size="sm" />
        </MDBox>
    ),
  },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];

const SearchBar = (props) => {

  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        // options={t}
        // renderInput={(params) => <TextField {...params} label="freeSolo" />}
      />
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        // options={top100Films.map((option) => option.title)}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     label="Search input"
        //     InputProps={{
        //       ...params.InputProps,
        //       type: 'search',
        //     }}
      />
      )}
      />
    </Stack>
  );

};

export default function TableList(props) {

  const { isLoading, tableList } = props;

  console.log("tttt", isLoading);
  return (
    <div style={{ height: 400, width: "100%" }}>
      {/*<SearchBar />*/}
      <DataGrid
        columns={columns}
        rows={tableList}
        pageSize={5}
        rowsPerPageOptions={[5]}
        loading={isLoading}
        // checkboxSelection
        // components={{
        //   LoadingOverlay:Spinner
        //
        // }}
      />
    </div>
  );
}
