import * as React from "react";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSelect from "components/MDSelect";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import Box from "@mui/material/Box";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { CardActions, CardContent, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Billing from "./order";
import Switch from "@mui/material/Switch";
import { Link, Route } from "react-router-dom";
import { searchTables } from "../TableSetup/Services";
import Button from "@mui/material/Button";
import PanSpinner from "../../components/Loader/PanSpinner";
import TextField from "@mui/material/TextField";
import { SearchRounded } from "@mui/icons-material";
import BillPage from "./BillPage";
import { fetchTableBillingInfo } from "./Services";
import TableQRModal from "./TableQRModal";
import tableImage from "./table.jpg";
import vacantTable from "./green-circle.png";
import occupiedTable from "./red-circle.png";
import Avatar from "@mui/material/Avatar";
import { fetchCategory } from "layouts/category/Services";
import { fetchSpace } from "layouts/space/Services";

const bull = (
  <Box component="span" sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}>
    •
  </Box>
);

const BillLink = (props) => <Link to="/billing" {...props} />;

// <Link to="/billing" {...props} state={{table:table}} />

const TableCard = (props) => {
  const { tableList } = props;

  const [billing, setBilling] = useState(false);
  const [table, setTable] = useState({});
  const [orderList, setOrderList] = useState([]);

  const [showTableQr, setShowTableQr] = useState(false);

  const fetchTableBilling = async (tableId, reservationNumber) => {
    setBilling(true);

    let requestDTO = {
      tableId,
      reservationNumber,
    };

    try {
      const response = await fetchTableBillingInfo(requestDTO);
      const orderList = response.data.tableOrderList;
      setOrderList(orderList);
    } catch (e) {
      alert(e.errorMessage);
    }
  };

  const setShowBilling = () => {
    setBilling(false);
  };

  const setShowQrModal = (flag, table) => {
    setShowTableQr(flag);

    if (table) setTable(table);
  };

  return (
    <>
      {billing === true ? (
        <BillPage billPage={billing} orderList={orderList} setBillPage={setShowBilling} />
      ) : (
        ""
      )}

      {showTableQr === true ? (
        <TableQRModal table={table} showTableQr={showTableQr} setShowQrModal={setShowQrModal} />
      ) : (
        ""
      )}

      {tableList && tableList.size != 0 ? (
        tableList.map((table) => (
          <Grid item xs={12} md={3} xl={2}>
            <>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  overflow: "visible",
                }}
              >
                <CardMedia
                  component="img"
                  width="80"
                  height="80"
                  image={tableImage}
                  alt="green iguana"
                />

                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      {table.name}
                      {table.state === "V" ? (
                        <>
                          <MDBox ml={2}>
                            {/*<MDBadge badgeContent="vacant" color="success" variant="gradient" size="sm" />*/}
                            <Avatar src={vacantTable} sx={{ height: 34, width: 34 }} />
                          </MDBox>
                        </>
                      ) : (
                        <>
                          <MDBox ml={2}>
                            {/*<MDBadge badgeContent="occupied" color="warning" variant="gradient" size="sm" />*/}

                            <Avatar src={occupiedTable} sx={{ height: 34, width: 34 }} />
                          </MDBox>
                        </>
                      )}
                    </div>

                    <MDBox ml={-1}>
                      <QrCode2Icon size={"sm"} onClick={() => setShowQrModal(true, table)} />
                    </MDBox>
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    Max {table.capacity} Person
                  </Typography>

                  <CardActions>
                    {table.state === "O" ? (
                      <>
                        <Button size="small">Transfer</Button>
                        <Button
                          size="small"
                          onClick={() => fetchTableBilling(table.id, table.reservationNumber)}
                        >
                          Bill
                        </Button>
                      </>
                    ) : (
                      ""
                    )}

                    {table.state === "V" ? (
                      <>
                        <Button size="small" component={BillLink}>
                          Take Order
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </CardActions>
                </CardContent>
              </Card>
            </>
          </Grid>
        ))
      ) : (
        <PanSpinner />
      )}
    </>
  );
};

const Tables = (props) => {
  const { tableName, status, description } = props;

  const [tableList, setTableList] = useState([]);

  const [spaces, setSpaces] = useState([]);

  const [searchFilter, setSearchFilter] = useState({
    name: "",
  });

  const [selectedSpace, setSelectedSpace] = useState();

  useEffect(async () => {
    await fetchTablesSearch();
    await fetchSpaces();
  }, [props]);

  const handleSearch = async (event) => {
    const { name, value } = event.target;

    setSearchFilter({
      name: value,
    });
  };

  const fetchTablesSearch = async () => {
    try {
      const response = await searchTables(searchFilter);
      const tableList = response.data;
      setTableList(tableList);
    } catch (e) {
      alert(e.errorMessage);
    }
  };

  const fetchSpaces = async () => {
    try {
      const response = await fetchSpace();
      setSpaces(response.data);
    } catch (error) {
      alert(error.errorMessage);
    }
  };

  const handleSpace = (e) => {
    setSelectedSpace(e.target.value);
    setSearchFilter((prevState) => ({
      ...prevState,
      spaceId: e.target.value,
    }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={1}
                mt={-7}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Table
                </MDTypography>
              </MDBox>

              <MDBox px={1} py={1}>
                <Stack direction="row" spacing={2}>
                  {/* <TextField
                    id="outlined-basic"
                    label="Category"
                    name={""}
                    onChange={handleSearch}
                    variant="outlined"
                  /> */}
                  <MDSelect
                    items={spaces}
                    value={selectedSpace}
                    onChange={handleSpace}
                    label="Space"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    name={"name"}
                    onChange={handleSearch}
                    variant="outlined"
                  />
                  <TextField id="outlined-basic" label="Status" variant="outlined" />
                  <Button
                    variant="contained"
                    startIcon={<SearchRounded />}
                    onClick={() => fetchTablesSearch()}
                  >
                    Search
                  </Button>
                </Stack>
              </MDBox>

              <MDBox pt={3}>
                <Grid container spacing={6}>
                  {/*<Grid item xs={12} md={6} xl={3}>*/}
                  <TableCard tableList={tableList} {...props} />
                </Grid>
                {/*</Grid>*/}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Switch>
        <Route path="/billing">
          <Billing />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

export default Tables;
