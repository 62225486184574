// @mui material components
import Grid from "@mui/material/Grid";

import * as React from "react";
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import { fetchDashboardTilesData, fetchSalesTrend } from "./Services";
import WelcomeNote from "./Welcomenote";
import BestFood from "./BestFood";
import configs from "../../examples/Charts/LineCharts/ReportsLineChart/configs";


// Dashboard components
function Dashboard() {

  const [tiles, setTiles] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [alignment, setAlignment] = React.useState("Y");
  const [dataset, setDataset] = useState({});

  useEffect(async () => {
    console.log("tetewtewtewt");

    await fetchDashboardChart();
    await fetchDashboardTiles();

  }, []);

  const handleChange = async (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {

    console.log("new alignement in handle::::", newAlignment);

    if (newAlignment)
      setAlignment(newAlignment);


    await fetchDashboardChart();
  };

  const fetchDashboardChart = async () => {

    const currentDate = new Date();

    const currentDayOfMonth = currentDate.getDay();
    const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear();
    console.log("aligment::::", alignment);

    let fromDate;
    let toDate;

    if (alignment === "D") {
      fromDate = currentYear + "-" + currentMonth + "-" + (currentDayOfMonth - 1);
      toDate = currentYear + "-" + currentMonth + "-" + currentDayOfMonth;

    }

    if (alignment === "W") {

      fromDate = currentYear + "-" + (currentMonth) + "-" + (currentDayOfMonth - 7);
      toDate = currentYear + "-" + currentMonth + "-" + currentDayOfMonth;

    }

    if (alignment === "M") {

      fromDate = currentYear + "-" + (currentMonth - 1) + "-" + (currentDayOfMonth);
      toDate = currentYear + "-" + currentMonth + "-" + currentDayOfMonth;

    }
    if (alignment === "Y") {

      console.log("inside year");

      fromDate = (currentYear - 1) + "-" + currentMonth + "-" + currentDayOfMonth;
      toDate = currentYear + "-" + currentMonth + "-" + currentDayOfMonth;
    }

    console.log("---------------------");
    console.log("---------------------", fromDate);
    console.log("---------------------", toDate);

    let requestDTO = {
      fromDate: fromDate + " 00:00:00",
      toDate: toDate + " 00:00:00",
    };

    console.log("requestDTO::::", requestDTO);

    try {
      const response = await fetchSalesTrend(requestDTO);
      const tiles = response.data;
      const keys = Object.keys(tiles.totalSale) || [];
      let dataList = Object.values(tiles.totalSale) || [];
      setIsLoading(false);
      // console.log("keys;::", keys);
      // console.log("dataList::", dataList);

      let datasets = {
        label: "Sales Statistics",
        data: dataList,
      };

      updateChart(keys, datasets);
    } catch (e) {
      // alert(e.errorMessage);
    }

  };

  const updateChart = (keys, datasets) => {
    const { data, options } = configs(keys || [], datasets || {});

    setDataset({
      data, options,
    });
  };


  const fetchDashboardTiles = async () => {

    try {
      const response = await fetchDashboardTilesData();
      const tiles = response.data;
      setTiles({
        daily: tiles.daily,
        weekly: tiles.weekly,
        monthly: tiles.monthly,
        yearly: tiles.yearly,
        totalSpace: tiles.totalSpace,
        totalTable: tiles.totalTable,
        totalKitchenItem: tiles.totalKitchenItem,
        totalBeverageItem: tiles.totalBeverageItem,
        totalCategory: tiles.totalCategory,

      });
      setIsLoading(false);
    } catch (e) {
      // alert(e.errorMessage);
    }

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>

        <MDBox mt={4.5}>

          <Grid container spacing={1}>

            <Grid item xs={12} md={3} lg={3}>
              <MDBox mb={3}>
                <WelcomeNote
                />
              </MDBox>
              <MDBox mt={2} mb={2}>
                <BestFood
                  tiles={tiles}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={9} lg={9}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="primary"
                  title="daily sales"
                  chart={dataset}
                  handleChange={handleChange}
                  alignment={alignment}
                  isLoading={isLoading}
                />
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Daily"
                count={"Rs " + 281}
                // <>
                //   {/*{isLoading === true ? <Spinner /> : tiles?.daily}*/}
                // </>}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Weekly"
                count={"Rs " + 281}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Monthly"
                count={
                  "Rs " + "34k"
                }
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Yearly"
                count={"Rs " + "+91"}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Dashboard;
