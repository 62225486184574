import Axios from "../../axios-interceptor";
import { spaceApiConstants } from "../../constants/AdminApiConstants";

export async function saveSpace(params) {
  return Axios.post(spaceApiConstants.SAVE_SPACE, params);
}

export async function searchSpace(data) {
  return Axios.put(spaceApiConstants.SEARCH_SPACE, data, { params: { page: 1, size: 10 } });
}

export async function fetchSpace() {
  return Axios.get(spaceApiConstants.SPACE_DROPDOWN);
}
