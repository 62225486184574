import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { appendSNToDataList } from "../../utils/TableUtils";

const columns = [
  { field: 'orderNumber', headerName: 'Order Number', width: 300 },
  { field: 'itemId', headerName: 'ID', width: 50 },
  { field: 'itemName', headerName: 'Name', width: 130 },
  { field: 'quantity', headerName: 'Quantity', width: 80 },
  { field: 'price', headerName: 'Price', width: 100 },
  { field: 'totalAmount', headerName: 'Total Amount', width: 130 },
  { field: 'tableName', headerName: 'Table Name', width: 130 },
  { field: 'date', headerName: 'Date', width: 120 },
  { field: 'time', headerName: 'Time', width: 180 },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];

export default function OrdersTable(props) {

  const {isLoading,ordersList}=props
  return (
    <div style={{ height: 700, width: '100%' }}>
      <DataGrid
        rows={appendSNToDataList(ordersList)}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[10]}
        loading={isLoading}
        // checkboxSelection
      />
    </div>
  );
}
