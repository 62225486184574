import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import CardMedia from "@mui/material/CardMedia";
import { BASE, FILE_BASE } from "../../constants/AdminApiConstants";
import { appendSNToDataList } from "../../utils/TableUtils";

const columns = [
  { field: "sn", headerName: "SN", width: 50 },
  {
    field: "photo", headerName: "Photo", width: 150,height:200,
    renderCell: (params) => (
      <CardMedia
        component="img"
        height="100"
        width="100"
        src={BASE + FILE_BASE + "/" + params.row.photo}
        alt="image"
      />
    ),
  },
  { field: "name", headerName: "Name", width: 150 },
  {
    field: "price", headerName: "Price", width: 150,
    valueGetter: (params) =>
      `Rs. ${params.row.price || ""}`,
  },
  { field: "categoryName", headerName: "Category Name", width: 150 },
  {
    field: "type", headerName: "Type", width: 150,
    renderCell: (params) => (
      <MDBox ml={-1}>
        <MDBadge badgeContent="KOT" color={"secondary"} size="sm" />
      </MDBox>
    )
  },
  {
    field: "status", headerName: "Status", width: 150,
    renderCell: (params) => (
      params.row.status === "Y" ?
        <MDBox ml={-1}>
          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
        </MDBox> :
        <MDBox ml={-1}>
          <MDBadge badgeContent="Inactive" color="warning" variant="gradient" size="sm" />
        </MDBox>
    ),
  },
];

export default function ItemTable(props) {

  const { itemList } = props;
  return (
    <div style={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={appendSNToDataList(itemList)}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        // checkboxSelection
      />
    </div>
  );
}
