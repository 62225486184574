// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import OrderInformation from "./components/OrderInformation";
import Category from "../Category";
import { searchCategory } from "../../category/Services";
import * as React from "react";
import { useEffect, useState } from "react";
import { searchItem } from "../../item/Services";
import ItemOrder from "../ItemOrder";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { styled, useTheme } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MDTypography from "../../../components/MDTypography";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useLocation } from "react-router-dom";


const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;

  console.log("chilodren:::" + children);
  const itemData = [];
  children.forEach((item) => {
    console.log("testtt::::" + item);
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

function random(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const OPTIONS = Array.from(new Array(10000))
  .map(() => random(10 + Math.ceil(Math.random() * 20)))
  .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));


// const OPTIONS= (itemList)= itemList
//   .map(() => random(10 + Math.ceil(Math.random() * 20)))
//   .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));


function Billing(props) {

  console.log("porppps:::"+props)

  const location = useLocation()

  console.log("location:::"+location)
  // const { table } = location.state
  //
  // console.log("from route tqable::::",table)

  const [categoryList, setCategoryList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [itemSearchErrorMessage, setSearchErrorMessage] = useState("");
  const [itemSearchFilter, setItemSearchFilter] = useState({
    name: "",
    categoryId: null,
  });

  const [showAddOrder, setShowAddOrder] = useState(false);
  const [item, setItem] = useState({});

  useEffect(async () => {
    await fetchCategorySearch();
    await fetchItemSearch();
  }, [props]);

  const fetchCategorySearch = async () => {

    let searchRequestDTO = {
      name: "",
    };

    try {
      const response = await searchCategory(searchRequestDTO);
      const categoryList = response.data;
      setCategoryList(categoryList);
    } catch (e) {
      alert(e.errorMessage);

    }

  };

  const fetchItemSearch = async () => {

    try {
      setItemList([]);
      const response = await searchItem(itemSearchFilter);
      const itemList = response.data;
      setItemList(itemList);
    } catch (e) {
      setSearchErrorMessage(e.errorMessage)

    }

  };

  const filterItemsByCategory = async (id) => {
    await setItemSearchFilter({
      name: "",
      categoryId: id,
    });

    await fetchItemSearch();
  };

  const showOrderModal = (flag, item) => {

    setShowAddOrder(flag);
    setItem(item);
  };

  const getNameList = (itemList) => itemList && itemList.map(item => {

    let nameList = [];
    nameList.push(item.name);
    return nameList;
  });

  const handleOnSearch = async (event) => {

    event.preventDefault();
    const { name, value } = event.target;

    console.log(name + "::::" + value);

    await setItemSearchFilter({
      name: value,
      categoryId: null,
    });

    try {
      const response = await searchItem(itemSearchFilter);
      const itemList = response.data;
      setItemList(itemList);
    } catch (e) {
      alert(e.errorMessage);

    }


  };


  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />

      {showAddOrder ?
        <ItemOrder
          showOrderModal={showOrderModal}
          item={item}
          showAddOrder={showAddOrder}
          fetchCategorySearch={fetchCategorySearch}
        /> : ""}

      <MDBox  mt={12}>
        <MDBox
          mt={-6}
          py={-3}
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Table Page
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => showAddModal(true)}
              color="inherit"
            >
              {/*<AddBox />*/}

              <AddShoppingCartIcon/>

            </IconButton>
          </MDTypography>

        </MDBox>
      </MDBox>

      <MDBox mt={8}>

        <Card id="delete-account">
          <MDBox pt={3} px={2}>
            {/*<MDTypography variant="h6" fontWeight="medium">*/}
            {/*  /!*Order Information*!/*/}
            {/*  <MDInput label="Search Food Items, Bevrages, etc" fullWidth={true} />*/}
            {/*</MDTypography>*/}

            <Autocomplete
              id="virtualize-demo"
              sx={{ width: 900 }}
              disableListWrap
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              options={getNameList(itemList)}
              onClose={() =>fetchItemSearch()}
              groupBy={(option) => option[0].toUpperCase()}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={itemList.length + " Options"}
                  onClick={(event) =>
                    handleOnSearch(event)}
                />}
              renderOption={(props, option) => [props, option]}
              // TODO: Post React 18 update - validate this conversion, look like a hidden bug
              renderGroup={(params) => params}
            />

          </MDBox>
        </Card>

        {/*</MDBox>*/}


        <MDBox mb={3}>
          <Grid container spacing={0.5}>

            <Grid item xs={12} lg={3}>
              <Category
                title="Category"
                categoryList={categoryList}
                filterItemsByCategory={filterItemsByCategory}
                shadow={false}
              />
            </Grid>

            <Grid item xs={12} lg={9}>
              <OrderInformation
                itemList={itemList}
                showOrderModal={showOrderModal}
                setItem={setItem}
                itemSearchErrorMessage={itemSearchErrorMessage}
              />
            </Grid>

            {/*<Grid item xs={12} lg={2}>*/}
            {/*  <Items />*/}
            {/*</Grid>*/}

          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
