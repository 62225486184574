import { CategoryRounded } from "@mui/icons-material";
import Axios from "../../axios-interceptor";
import { categoryApiConstants } from "../../constants/AdminApiConstants";

export async function saveCategory(params) {
  return Axios.post(categoryApiConstants.SAVE_CATEGORY, params);
}

export async function searchCategory(data) {
  return Axios.put(categoryApiConstants.SEARCH_CATEGORY, data, { params: { page: 1, size: 10 } });
}
export async function fetchCategory() {
  return Axios.get(categoryApiConstants.CATEGORY_DROPDOWN);
}
