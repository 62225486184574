import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Quote from "../../data/Quotes.json";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const greetings = () => {

  const today = new Date();
  console.log("ttt", today);
  const hours = today.getHours();
  console.log("hourrrrrssssss", hours);

  return hours < 12 ? "Good Morning" :
    hours < 18 ? "Good Afternoon" :
      "Good Evening";
};

const getQuotes = () => {

  const quote=JSON.parse(JSON.stringify(Quote));
  console.log("size",quote.length)
  const size=Math.floor(Math.random()*quote.length);

  const result=quote[size].quote;
  return result;


};

const card = (
  <React.Fragment>
    <CardContent sx={{backgroundColor:"#FFC70B"}}>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Greetings 🚀🚀🚀
      </Typography>
      <Typography variant="h5" component="div">
        Hi ! Rupak {greetings()}
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Quote of the Day
      </Typography>
      <Typography variant="body2">
        {getQuotes()}
        {/*<br />*/}
        {/*{"\"a benevolent smile\""}*/}
      </Typography>
    </CardContent>
    {/*<CardActions>*/}
    {/*  <Button size="small">Learn More</Button>*/}
    {/*</CardActions>*/}
  </React.Fragment>
);

export default function WelcomeNote() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}