export const BASE = "https://api.agrobotics.app/restaurant-client/api/v1";
// export const BASE = "http://34.254.184.190:8080/restaurant-client/api/v1";
// export const BASE = "http://localhost:8080/client/api/v1";

const CLIENT_BASE = "/client";
const CLIENT_TYPE_BASE = "/client-type";

const DASHBOARD_BASE = "/dashboard";
const CATEGORY_BASE = "/category";
const ITEM_BASE = "/item";
const SPACE_BASE = "/space";
const TABLES_BASE = "/table";
const ORDER_BASE = "/order";
const ORDERS_BASE = "/orders";
const USER_BASE = "/user";

const DETAIL = "/detail";
export const FILE_BASE = "/file";

const ALL = "/all";
const FILE = "/file";
const SEARCH = "/search";
const USER_INFO = "/userInfo";
// const DETAIL = '/detail'
// const TRANSFER = '/transfer'
// const INFO = '/info'
const LOGIN = "/login";
const TILES = "/tiles";
// const PIE_CHART = '/pie-chart'

const UPLOAD = "/upload";
// const DOWNLOAD = '/download'

const ACTIVE = "/active";
const MIN = "/min";

// ------------ADMIN-----------------------------

export const dashboardApiConstants = {
  FETCH_DASHBOARD_TILES: BASE.concat(DASHBOARD_BASE.concat("/tiles")),
  FETCH_SALES_TREND: BASE.concat(DASHBOARD_BASE.concat("/sales-trend")),
};

export const categoryApiConstants = {
  SAVE_CATEGORY: BASE.concat(CATEGORY_BASE),
  SEARCH_CATEGORY: BASE.concat(CATEGORY_BASE.concat(SEARCH)),
  CATEGORY_DROPDOWN: BASE.concat(CATEGORY_BASE.concat(ACTIVE.concat(MIN))),
};

export const fileApiConstants = {
  UPLOAD_FILE: BASE.concat(FILE_BASE.concat(UPLOAD)),
  DOWNLOAD_FILE: BASE.concat(FILE_BASE),
};


export const itemApiConstants = {
  SAVE_ITEM: BASE.concat(ITEM_BASE),
  SEARCH_ITEM: BASE.concat(ITEM_BASE.concat(SEARCH)),
};

export const loginApiConstants = {
  LOGIN_USER: BASE.concat(LOGIN),
};

export const spaceApiConstants = {
  SAVE_SPACE: BASE.concat(SPACE_BASE),
  SEARCH_SPACE: BASE.concat(SPACE_BASE.concat(SEARCH)),
  SPACE_DROPDOWN: BASE.concat(SPACE_BASE.concat(ACTIVE.concat(MIN))),
};

export const billingApiConstants = {
  TABLE_BILLING: BASE.concat("/billing".concat("/table-billing")),
};

export const orderApiConstants = {
  SAVE_ORDER: BASE.concat(ORDER_BASE),
  SEARCH_ORDER: BASE.concat(ORDER_BASE.concat("/order-cart")),
};

export const ordersApiConstants = {
  SEARCH_ORDERS: BASE.concat(ORDER_BASE.concat(SEARCH)),
};

export const tableApiConstants = {
  SAVE_TABLE: BASE.concat(TABLES_BASE),
  SEARCH_TABLE: BASE.concat(TABLES_BASE.concat(SEARCH)),
};

export const userApiConstants = {
  SAVE_USER: BASE.concat(USER_BASE),
  SEARCH_USER: BASE.concat(USER_BASE.concat(SEARCH)),
  USER_INFO: BASE.concat(USER_BASE.concat(USER_INFO)),
};

// ------------CLIENT-----------------------------
