import Axios from "../../axios-interceptor";
import { userApiConstants } from "../../constants/AdminApiConstants";


export async function saveUser(params) {
  return Axios.post(userApiConstants.SAVE_USER, params);
}

export async function searchUser(data) {
  return Axios.put(userApiConstants.SEARCH_USER, data, {params: {page: 1, size: 10}});
}
