import * as React from "react";
import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// ReportsLineChart configurations
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Spinner from "../../../../components/Loader/Spinner";

function ReportsLineChart({ color, chart, handleChange, alignment,isLoading }) {


  const { data, options } = chart;
  console.log("data:::", data);
  console.log("options:::", options);

  return (
    <Card sx={{ height: "100%" }}>

      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="D">Daily</ToggleButton>
        <ToggleButton value="W">Weekly</ToggleButton>
        <ToggleButton value="M">Monthly</ToggleButton>
        <ToggleButton value="Y">Yearly</ToggleButton>
      </ToggleButtonGroup>

      <MDBox mb={4}></MDBox>

      <MDBox padding="1rem">
        {useMemo(
          () => (

            (isLoading === true ? <Spinner sx={{ position: "center" }} /> :
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              <Line data={data} options={options} />
            </MDBox>
            )
          ),
          [chart, color]
        )}
        <MDBox pt={3} pb={1} px={1}>

        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
};

export default ReportsLineChart;
