// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
// Dashboard components
import CategoryTable from "./CategoryTable";
import { useEffect, useState } from "react";
import { searchCategory } from "./Services";
import MDTypography from "../../components/MDTypography";
import IconButton from "@mui/material/IconButton";
import { AddBox } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SpaceAdd from "../space/SpaceAdd";
import CategoryAdd from "./CategoryAdd";

function Category() {

  const [categoryList, setCategoryList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(async () => {

    await fetchCategorySearch();

  }, []);

  const fetchCategorySearch = async () => {

    let searchRequestDTO = {
      name: "",
    };

    try {
      const response = await searchCategory(searchRequestDTO);
      const categoryList = response.data;
      setCategoryList(categoryList);
    } catch (e) {
      alert(e.errorMessage);

    }

  };

  const showAddModal = async (flag) => {

    setShowAdd(flag);

  };


  return (
    <DashboardLayout>
      <DashboardNavbar />

      {showAdd ?
        <CategoryAdd
          showAddModal={showAddModal}
          showAdd={showAdd}
          fetchCategorySearch={fetchCategorySearch}
        /> : ""}

      <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>

            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Category
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => showAddModal(true)}
                  color="inherit"
                >
                  <AddBox />
                </IconButton>
              </MDTypography>

            </MDBox>

      <MDBox py={3}>
        <CategoryTable
        categoryList={categoryList}/>
      </MDBox>

          </Card>
        </Grid>
      </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Category;
