// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import Grid from "@mui/material/Grid";
import Item from "../../../Item";
import { BASE, FILE_BASE } from "../../../../../constants/AdminApiConstants";

// Billing page components

function OrderInformation(props) {

  const {
    itemList,
    showOrderModal,
    setItem,
    itemSearchErrorMessage
  } = props;
  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {/*Order Information*/}
          <MDInput label="Search Food Items, Bevrages, etc" fullWidth={true} />
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>

        <MDBox p={2}>

          <Grid container spacing={6}>
            {itemList.length>0 ?itemList.map((item) => (
              <Grid item xs={12} md={6} xl={3}>
                <Item
                  image={BASE + FILE_BASE + "/" + item.photo}
                  item={item}
                  title="modern"
                  description={item.description || "N/A"}
                  showOrderModal={showOrderModal}
                  // action={{
                  //   type: "internal",
                  //   route: "/pages/profile/profile-overview",
                  //   color: "info",
                  //   label: "view project",
                  // }}
                />
              </Grid>
            )):
              <div>
                {itemSearchErrorMessage}
              </div>

            }
          </Grid>

        </MDBox>

      </MDBox>
    </Card>
  );
}

export default OrderInformation;
