import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/tables/order";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";
import Orders from "./layouts/orders/Orders";
import Item from "./layouts/item/Item";
import Space from "./layouts/space/Space";
import Table from "./layouts/TableSetup/Table";
import Category from "./layouts/category/Category";
import Logout from "./layouts/Logout/Logout";
import User from "./layouts/user/User";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/orders",
    component: <Orders />,
  },
  {
    type: "title",
    title: "Inventory",
    key: "inventory",
  },
  {
    type: "collapse",
    name: "Item Setup",
    key: "item",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/item-setup",
    component: <Item />,
  },

  {
    type: "collapse",
    name: "Category Setup",
    key: "category",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/category",
    component: <Category />,
  },
  {
    type: "title",
    title: "Settings",
    key: "tablesetup",
  },
  {
    type: "collapse",
    name: "Table Setup",
    key: "table",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/table-setup",
    component: <Table />,
  },
  {
    type: "collapse",
    name: "Space Setup",
    key: "space",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/space-setup",
    component: <Space />,
  },

  {
    type: "collapse",
    name: "Staff Setup",
    key: "staff",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/staff-setup",
    component: <User />,
  },

  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/logout",
    component: <Logout />,
  },
];

export default routes;
