import Axios from "../../axios-interceptor";
import { billingApiConstants, orderApiConstants } from "../../constants/AdminApiConstants";


export async function saveTableOrder(params) {
  return Axios.post(orderApiConstants.SAVE_ORDER, params);
}

export async function searchTableOrder(data) {
  return Axios.put(orderApiConstants.SEARCH_ORDER, data);
}

export async function fetchTableBillingInfo(data) {
  return Axios.put(billingApiConstants.TABLE_BILLING, data);
}