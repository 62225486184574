

export const appendSNToDataList=(dataList)=>{

  console.log("dataList:::::",dataList)

  // console.log("datalist,",dataList)

  let startingNumber = 1
  // if (page && page > 1) {
  //   startingNumber += (page - 1) * size
  // }
  let SNAddedData =
    dataList &&
    dataList.map(data => {
      let dataWithSN = {
        ...data,
        "sn":  startingNumber,
      }
      startingNumber++
      return dataWithSN
    })

  // console.log("SNAddedData",SNAddedData)

  return SNAddedData ? SNAddedData : []

}
