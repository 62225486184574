import Axios from "../../axios-interceptor";
import { itemApiConstants } from "../../constants/AdminApiConstants";

export async function saveItem(params) {
  return Axios.post(itemApiConstants.SAVE_ITEM, params);
}

export async function searchItem(data) {
  return Axios.put(itemApiConstants.SEARCH_ITEM, data, {params: {page: 1, size: 10}});
}
