import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import { appendSNToDataList } from "../../utils/TableUtils";
import CardMedia from "@mui/material/CardMedia";
import { BASE, FILE_BASE } from "../../constants/AdminApiConstants";

import maleIcon from "assets/images/icons/gender/maleicon.png";
import femaleIcon from "assets/images/icons/gender/femaleicon.png";

const columns = [
  { field: "sn", headerName: "SN", width: 50 },
  {
    field: "photo", headerName: "Photo", width: 250,height:1000,
    renderCell: (params) => (
      <CardMedia
        component="img"
        height="150"
        width="150"
        src={params.row.photo?
          BASE + FILE_BASE + "/" + params.row.photo:maleIcon
      }
        alt="image"
      />
    ),
  },
  { field: "fullName", headerName: "Name", width: 150 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "role", headerName: "Role", width: 150 },
  {
    field: "status", headerName: "Status", width: 130,
    renderCell: (params) => (
      params.row.status === "Y" ?
        <MDBox ml={-1}>
          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
        </MDBox> :
        <MDBox ml={-1}>
          <MDBadge badgeContent="Inactive" color="warning" variant="gradient" size="sm" />
        </MDBox>
    ),
  },
];

export default function UserTable(props) {

  const { isLoading, userList } = props;
  return (
    <div style={{ height: 800, width: "100%" }}>
      <DataGrid
        rows={appendSNToDataList(userList)}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        loading={isLoading}
        // checkboxSelection
      />
    </div>
  );
}
