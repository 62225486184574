import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { FormControl, Input, InputLabel, OutlinedInput, Select } from "@mui/material";
import { saveItem } from "./Services";
import { fetchActiveCategoryDropdown, uploadFile } from "../CommonServices";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { PhotoCamera } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ItemAdd(props) {

  const {
    showAdd,
    showAddModal,
    fetchItemSearch,
  } = props;
  console.log("ddd" + showAdd);

  const [item, setItem] = useState({
    name: "",
    categoryId: "",
    price: "",
    photo: "",
    description: "",
  });

  const [categoryList, setCategoryList] = React.useState([]);


  useEffect(async () => {

    if (showAdd) {
      await fetchActiveCategoryForDropdown();
    }

  }, [showAdd]);

  const fetchActiveCategoryForDropdown = async () => {

    try {
      const response = await fetchActiveCategoryDropdown();
      setCategoryList(response.data);
    } catch (e) {
      alert(e.errorMessage);

    }

  };


  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    console.log(name + ":::" + value);
    setItem({
      ...item,
      [name]: value,
    });

  };

  const submitItem = async () => {

    try {
      await saveItem(item);
      showAddModal(false);
      fetchItemSearch();
    } catch (e) {
      alert(e.errorMessage);

    }

  };

  const uploadImage = async (event) => {
    event.preventDefault();
    const {name, files} = event.target;
    console.log("name:::"+event.target)
    console.log("name:::"+name)
    console.log("files:::"+files)
    if (name === "photo" && files !== null) {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("subDirectory", "item");

      uploadFile(formData)
        .then(async (res) => {

          await setItem({
            ...item,
            "photo": res.data,
          });

        })
        .catch((e) => {
         alert("Error uploading file")
        });

      // const {photo} = this.state;
      // await this.setState((prevState) => ({
      //   ...prevState,
      //   animal: {
      //     ...prevState.animal,
      //     avatar: photo,
      //   },
      // }));
    }
  };


  const { name, categoryId,price, description } = item;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showAdd}
        onClose={() => showAddModal(false)}>
        <DialogTitle>Add Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create new Item
          </DialogContentText>

          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >

            {/*<Button variant="contained" component="label">*/}
            {/*  Image Upload*/}
            {/*  <input hidden accept="image/*" multiple type="file" />*/}
            {/*</Button>*/}
            <IconButton
              color="primary"
                        aria-label="upload picture"
                        component="label">
              <input
                hidden accept="image/*"
                type="file"
                name={"photo"}
                onChange={uploadImage}
              />
              <PhotoCamera />
            </IconButton>

            <div>
              <FormControl variant="standard">
                <InputLabel htmlFor="component-simple">Name</InputLabel>
                <Input
                  id="component-simple"
                  name={"name"}
                  value={name}
                  onChange={handleOnChange} />
              </FormControl>

            </div>

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Name</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={categoryId}
                name={"categoryId"}
                onChange={handleOnChange}
                placeholder={"Please Select"}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {categoryList && categoryList.map((category) => (
                  <MenuItem
                    key={category.value}
                    value={category.value}
                  >
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard">
              <InputLabel htmlFor="component-simple">Price(Rs.)</InputLabel>
              <Input
                id="component-simple"
                name={"price"}
                value={price}
                onChange={handleOnChange} />
            </FormControl>


            <FormControl fullWidth variant="standard">
              <TextField
                id="outlined-textarea"
                label="Description"
                placeholder="Description"
                name={"description"}
                value={description}
                value={description}
                onChange={handleOnChange}
                multiline
                autoSize
              />
            </FormControl>

          </Box>

        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => showAddModal(false)}>Cancel</Button>
          <Button variant="text" onClick={() => submitItem()}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ItemAdd;
