import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { FormControl, Input, InputLabel } from "@mui/material";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import TableCart from "./TableCart";
import CardMedia from "@mui/material/CardMedia";
import { BASE, FILE_BASE } from "../../constants/AdminApiConstants";
import { saveTableOrder, searchTableOrder } from "./Services";
import BillPage from "./BillPage";

export default function ItemOrder(props) {

  const {
    showAddOrder,
    showOrderModal,
    item,
  } = props;
  console.log("ddd" + showAddOrder);
  console.log("item" + item);

  const { name, price, photo } = item;
  const [orderList, setOrderList] = useState([]);
  const [billPage, setBillPage] =  useState(false);

  const [order, setOrder] = useState({
    quantity: 1,
    totalAmount: price,
    description: "",
  });

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "quantity") {
      setOrder({
        ...order,
        totalAmount: price * value,
        quantity: value,
        price: item.price || 0,
      });
    } else {

      console.log(name + ":::" + value);
      setOrder({
        ...order,
        [name]: value,
      });

    }

  };

  useEffect(async () => {

    await fetchTableOrderSearch();

  }, []);

  const submitOrder = async () => {
    setBillPage(true);
  };

  const handleAddOrder = async () => {

    let requestDTO = {
      itemId: item.id,
      tableId: 1,
      totalAmount: order.totalAmount || 0,
      quantity: order.quantity || 1,
      description: order.description || "",
    };

    try {
      await saveTableOrder(requestDTO);
      fetchTableOrderSearch();
    } catch (e) {
      alert(e.errorMessage);

    }


  };

  const fetchTableOrderSearch = async () => {

    let searchRequestDTO = {
      tableId: 1,
    };

    try {
      const response = await searchTableOrder(searchRequestDTO);
      const orderList = response.data;
      setOrderList(orderList);
    } catch (e) {
      alert(e.errorMessage);

    }

  };


  const { quantity, description, totalAmount } = order;

  return (

    <>

      {billPage==true?
        <BillPage
          billPage={billPage}
          orderList={orderList}
          setBillPage={setBillPage}/>:""
      }

      <div>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={showAddOrder}
          onClose={() => showOrderModal(false)}>
          <DialogTitle>Add Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Create new Order
            </DialogContentText>

            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <MDBox mt={1} mx={0.5}>

                      <MDBox position="relative" width="50%" shadow="xl" borderRadius="xl">
                        <CardMedia
                          src={BASE + FILE_BASE + "/" + item.photo}
                          component="img"
                          // title={title}
                          sx={{
                            maxWidth: "50%",
                            margin: 0,
                            boxShadow: ({ boxShadows: { md } }) => md,
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </MDBox>

                      <MDTypography
                        variant="h6"
                        textTransform="capitalize">
                        {name}
                      </MDTypography>

                      <MDTypography
                        variant="button"
                        color={"error"}
                        fontWeight="regular"
                        textTransform="capitalize">
                        {price} * {quantity} = Rs. {totalAmount + `.00`}
                      </MDTypography>
                    </MDBox>

                    <div>
                      <FormControl variant="standard">
                        <InputLabel htmlFor="component-simple">Quantity</InputLabel>
                        <Input
                          id="component-simple"
                          name={"quantity"}
                          value={quantity}
                          type={"number"}
                          inputProps={{ maxLength: 10 }}
                          onChange={handleOnChange} /> Plate(s)
                      </FormControl>

                    </div>

                    <FormControl fullWidth variant="standard">
                      <TextField
                        id="outlined-textarea"
                        label="Description"
                        placeholder="Description"
                        name={"description"}
                        value={description}
                        onChange={handleOnChange}
                        multiline
                        autoSize
                      />
                    </FormControl>

                    <Button
                      variant="contained"
                      onClick={() => handleAddOrder()}>Add</Button>

                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TableCart
                    orderList={orderList}
                  />
                </Grid>
              </Grid>
            </MDBox>

          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => showOrderModal(false)}>Cancel</Button>
            <Button variant="text" onClick={() => submitOrder()}>Order</Button>
          </DialogActions>
        </Dialog>
      </div>

    </>
  );
}
