// react-router-dom components
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";

function Item(props) {

  const { item, image, showOrderModal } = props;
  const {
    name,
    title,
    price,
  } = item;


  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            maxWidth: "100%",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </MDBox>
      <MDBox mt={1} mx={0.5}>

        <MDTypography
          variant="h6"
          >
          {name}
        </MDTypography>

        <MDTypography
          variant="button"
          color={"error"}
          fontWeight="regular"
          textTransform="capitalize">
          Rs. {price + `.00`}
        </MDTypography>

        <br></br>
        <Button variant={"text"} onClick={() => showOrderModal(true, item)}>Add Order</Button>

      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
Item.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
Item.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default Item;
