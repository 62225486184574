import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { FormControl, Input, InputLabel } from "@mui/material";
import { saveUser } from "./Services";

export default function UserAdd(props) {

  const {
    showAdd,
    showAddModal,
    fetchUserSearch
  } = props;
  console.log("ddd" + showAdd);

  const [space, setUser] = useState({
    name: "",
    description: "",
  });

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    console.log(name + ":::" + value);
    setUser({
      ...space,
      [name]: value,
    });

  };

  const submitUser = async () => {

    try {
      await saveUser(space);
      showAddModal(false);
      fetchUserSearch()
    } catch (e) {
      alert(e.errorMessage);

    }

  };

  const { name, description } = space;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showAdd}
        onClose={() => showAddModal(false)}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create new User
          </DialogContentText>

          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <FormControl variant="standard">
                <InputLabel htmlFor="component-simple">Name</InputLabel>
                <Input
                  id="component-simple"
                  name={"name"}
                  value={name}
                  onChange={handleOnChange} />
              </FormControl>

            </div>

            <FormControl fullWidth variant="standard">
              <TextField
                id="outlined-textarea"
                label="Description"
                placeholder="Description"
                name={"description"}
                value={description}
                value={description}
                onChange={handleOnChange}
                multiline
                autoSize
              />
            </FormControl>

          </Box>

        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => showAddModal(false)}>Cancel</Button>
          <Button variant="text" onClick={() => submitUser()}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
