import Axios from "../../axios-interceptor";
import { dashboardApiConstants } from "../../constants/AdminApiConstants";

export async function fetchDashboardTilesData() {
  return Axios.get(dashboardApiConstants.FETCH_DASHBOARD_TILES);
}

export async function fetchSalesTrend(data) {
  return Axios.put(dashboardApiConstants.FETCH_SALES_TREND,data);
}
